<template>
  <div>
    <div
      v-if="viewAreaTicketsPermission"
      :class="{ appLoggedOff : isLoggedOff }"
      class="appSectionContainer"
    >
      <div class="appSectionBody scSectionBody">
        <div
          class="impactFilterContainer"
        >
          <div
            :class="getServiceImpactColour('critical')"
            class="priorityIcon"
            @click="applyFilter('emergency')"
          >
            Emergency Category {{ `(${getTicketCount('Emergency Category')})` }}
          </div>
          <div
            :class="getServiceImpactColour('high')"
            class="priorityIcon"
            @click="applyFilter('high')"
          >
            High Impact {{ `(${getTicketCount('High Impact')})` }}
          </div>
          <div
            :class="getServiceImpactColour('medium')"
            class="priorityIcon"
            @click="applyFilter('medium')"
          >
            Medium Impact {{ `(${getTicketCount('Medium Impact')})` }}
          </div>
          <div
            :class="getServiceImpactColour('low')"
            class="priorityIcon"
            @click="applyFilter('low')"
          >
            Low Impact {{ `(${getTicketCount('Low Impact')})` }}
          </div>
          <div
            :class="getServiceImpactColour('risk')"
            class="priorityIcon"
            @click="applyFilter('risk')"
          >
            Risk Only {{ `(${getTicketCount('Risk Only')})` }}
          </div>
          <div
            :class="getServiceImpactColour('')"
            class="priorityIcon"
            @click="applyFilter('')"
          >
            All {{ `(${getTicketCount('')})` }}
          </div>
        </div>
        <div
          class="dayFilterContainer"
        >
          <div
            :class="getDayFilterColour(1)"
            class="dayFilter"
            @click="applyDayFilter(1)"
          >Due Today</div>
          <div
            :class="getDayFilterColour(7)"
            class="dayFilter"
            @click="applyDayFilter(7)"
          >Due Within 7 Days</div>
          <div
            :class="getDayFilterColour(10)"
            class="dayFilter"
            @click="applyDayFilter(10)"
          >10 Day Window</div>
          <div
            :class="getDayFilterColour(28)"
            class="dayFilter"
            @click="applyDayFilter(28)"
          >Due Within 28 Days</div>
          <div
            :class="getDayFilterColour(0)"
            class="dayFilter"
            @click="applyDayFilter(0)"
          >All Notifications</div>
        </div>
        <div
          :class="{mdHalfScreen : !!selectedTicket}"
          class="mdTickets"
        >
          <div
            v-show="isLoading"
            class="divSpinner"
          >
            <img
              :src="maintelSpinnerImage"
              alt="loading tickets, please wait..."
            >
            <p class="pSpinnerText">loading tickets, please wait...</p>
          </div>
          <table
            v-show="hasTickets && !isLoading"
            id="ticketsTable"
          >
            <thead />
            <tbody>
              <tr
                v-for="v in currentFilter"
                :key="v.id"
                :tabindex="20"
                :class="ticketClass(selected == v.id,v.Service_Impact)"
                @click="selectTicket(v)"
                @keyup="testSelectTicket($event, v)"
              >
                <td :title="v.Ticket_Number">{{ v.Ticket_Number }}</td>
                <td
                  :title="v.PM_Reference">{{ v.PM_Reference }}
                </td>
                <td
                  :title="v.Title">{{ v.Title }}
                </td>
                <td
                  :title="v.PM_Type">{{ v.PM_Type }}
                </td>
                <td
                  :title="v.Date_Due">{{ v.Date_Due }}
                </td>
                <td :title="v.Estimated_Hours">{{ v.Estimated_Hours }}</td>
                <td :title="v.Service_Impact">{{ v.Service_Impact }}</td>
                <td :title="v.PM_Category">{{ v.PM_Category }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-show="!hasTickets && !isLoading"
            class="noResults"
          >
            Can’t see your ticket? Call our service desk on 0344 871 1122 (option 2 for support)
          </div>
        </div>
        <div
          v-if="!!selectedTicket"
          class="mdSelection"
        >
          <pmnTicketDetailsComponent
            :ticket="selectedTicket"
            :full-ticket="selectedTicketFull"
            :show-details="showDetails"
            :show-new-notes="true"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!viewAreaTicketsPermission && !isLoading"
      class="noResults"
    >
      Sorry, you do not have permission to view tickets.
    </div>
  </div>
</template>

<script>
import { ticketMixin } from '../mixins/ticket'
import tableHandler from '../mixins/tableHandler'
import { permissionsMixin } from '../mixins/permissions'
import { mapActions } from 'vuex'
import { DataEventBus } from '../events/dataEvents'
import { autotaskServiceMixin } from '../mixins/autotaskServiceMixin'
import pmnTicketDetailsComponent from '../components/tickets/pmnTicketDetailsComponent'
import dialogBox from '../components/common/dialogBox'
import TriStateToggleSwitch from '../components/common/triStateToggleSwitch'
import TextButton from '../components/common/textButton.vue'

export default {
  metaInfo: {
    title: 'icon portal - Planned Maintenance'
  },
  components: {
    pmnTicketDetailsComponent,
    dialogBox,
    TriStateToggleSwitch,
    TextButton
  },
  mixins: [
    ticketMixin,
    tableHandler,
    permissionsMixin,
    autotaskServiceMixin
  ],
  data () {
    return {
      visibleTickets: [],
      selectedTicket: null,
      selectedTicketFull: null,
      selected: null,
      ticketData: [],
      dialog: false,
      displayCompletedTickets: false,
      displayIncompleteTickets: true,
      displayWaitingTickets: true,
      displayInProcessTickets: true,
      displayServiceRestoredTickets: true,
      loadingTickets: false,
      showDetails: false,
      currentDayFilter: 0
    }
  },
  computed: {
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    currentTickets () {
      return this.$store.getters.pmnTickets
    },
    currentTicketUDFs () {
      return this.$store.getters.ticketUDFs
    },
    currentSites () {
      return this.$store.getters.sites
    },
    hasTickets () {
      return this.visibleTickets.length !== 0
    },
    isLoading () {
      if (this.currentTickets) {
        return this.currentTickets.length !== 0 && this.loadingTickets
      } else {
        return false
      }
    },
    maintelSpinnerImage () {
      return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.spinnerImage}`
    },
    ticketLink () {
      if (this.$route.query.ticket) {
        return this.$route.query.ticket
      } else {
        return ''
      }
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    viewAreaTicketsPermission () {
      if (!this.loadingTickets) {
        this.computeTickets()
      }
    },
    currentTickets () {
      this.computeTickets()
    },
    currentSites () {
      this.computeTickets()
    },
    ticketsFilter () {
      this.computeTickets()
    }
  },
  created () {
    this.checkPermission()
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.visibleTickets = this.ticketData
      this.setUpTable(this.visibleTickets)
      if (this.ticketLink.length > 0) {
        this.openLinkedTicket()
      }
    })
    DataEventBus.$on('company-changed', () => {
      this.setTickets([])
      this.visibleTickets = []
      this.selectedTicket = null
    })
    DataEventBus.$on('loading-tickets', () => {
      this.loadingTickets = true
    })
    DataEventBus.$on('tickets-loaded', () => {
      this.computeTickets()
      this.loadingTickets = false
    })
    this.setUpTable([])
    this.computeTickets()
    DataEventBus.$emit('still-active')
  },
  methods: {
    ...mapActions([
      'setPage',
      'setTickets'
    ]),
    setUpTable (data) {
      this.initialiseTable('ticketsTable',
        data,
        ['Ticket_Number', 'PM_Reference', 'Title', 'PM_Type', 'Date_Due', 'Estimated_Hours', 'Service_Impact', 'PM_Category'],
        ['Ticket Number', 'Reference', 'Title', 'PM Type', 'Due Date', 'Duration', 'Impact', 'Category'],
        ['', '', '', '', 'Date', 'Number', '', ''],
        null,
        ['10%', '10%', '30%', '10%', '10%', '10%', '10%', '10%'],
        null,
        true,
        'maintel_pmn_tickets.csv'
      )
    },
    selectTicket: async function (selectedRecord) {
      this.showDetails = false
      this.selectedTicketFull = this.$store.getters.pmnTickets.filter(ticket => ticket.id === selectedRecord.id)[0]
      selectedRecord.Contact_Name = 'Unknown'
      this.selectedTicketFull.createdByName = 'Unknown'
      // this.selectedTicketFull.createdByName = 'Unknown'
      this.selectedTicket = selectedRecord
      if (this.selected === selectedRecord.id) {
        this.selected = null
        this.selectedTicket = null
      } else {
        this.selected = selectedRecord.id
      }
      // DataEventBus.$emit('still-active')
    },
    testSelectTicket (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectTicket(event, selectedRow)
      }
    },
    computeTickets () {
      this.ticketData = []
      if (this.viewAreaTicketsPermission) {
        if (this.pmnTicketList) {
          var daysFilter = this.$route.query.daysFilter
          var ticketsFilter = this.$route.query.ticketsFilter
          var filteredTickets = this.pmnTicketList
          if (daysFilter) {
            filteredTickets = this.getDayTickets(daysFilter)
          }
          if (ticketsFilter) {
            filteredTickets = this.getFiteredTickets(ticketsFilter)
          }
          filteredTickets
            .forEach(element => {
              var pmType = this.getUDFValue(element.id, 'Planned Maintenance Type')
              if (pmType !== 'WSUS') {
                var _tempTicket = {
                  id: element.id,
                  Title: element.title,
                  Ticket_Number: element.ticketNumber,
                  Date_Added: this.getScreenDate(element.createDateTime),
                  Date_Due: this.getScreenDate(element.dueDateTime),
                  Estimated_Hours: element.estimatedHours,
                  Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
                  Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
                  Problem_Ticket_Id: element.problemTicketId,
                  Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
                  Service_Impact: this.getUDFValue(element.id, 'Service Impact'),
                  PM_Reference: this.getUDFValue(element.id, 'Planned Maintenance Reference'),
                  PM_Category: this.getUDFValue(element.id, 'Planned Maintenance Category'),
                  PM_Type: pmType
                }
                if (element.accountId != null && element.accountId !== 0) {
                  const siteObj = this.$store.getters.site(element.accountId)
                  if (siteObj) {
                    _tempTicket.Site = siteObj.name
                  }
                }
                this.ticketData.push(_tempTicket)
              }
            })
          this.ticketData = this.ticketData.sort(this.sortBy('-Ticket_Number'))
        }
        this.visibleTickets = this.ticketData
        this.setUpTable(this.visibleTickets)
      }
    },
    checkPermission: async function () {
      this.setPage('Tickets')
      if (this.viewAreaTicketsPermission) {
        this.computeTickets()
      } else {
        this.setPage('Login')
      }
    },
    getIssueTypeFromId (issueTypeId) {
      if (issueTypeId && this.$store.getters.issueTypeList) {
        var rtn = this.$store.getters.issueTypeList.find(p => p.value === issueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    getSubIssueTypeFromId (subIssueTypeId) {
      if (subIssueTypeId) {
        var rtn = this.$store.getters.subIssueTypeList.find(p => p.value === subIssueTypeId.toString())
        if (rtn) {
          return rtn.name
        } else {
          return 'Unknown'
        }
      } else {
        return 'Unknown'
      }
    },
    ticketClass (selected, serviceImpact) {
      var tClass = 'Grey'
      if (serviceImpact.toLowerCase().indexOf('major') > -1 || serviceImpact.toLowerCase().indexOf('critical') > -1) {
        tClass = 'Purple'
      } else if (serviceImpact.toLowerCase().indexOf('high') > -1) {
        tClass = 'Red'
      } else if (serviceImpact.toLowerCase().indexOf('low') > -1) {
        tClass = 'Green'
      } else if (serviceImpact.toLowerCase().indexOf('medium') > -1) {
        tClass = 'Amber'
      } else if (serviceImpact.toLowerCase().indexOf('risk') > -1) {
        tClass = 'Blue'
      }
      if (!this.useAltColour) {
        tClass = 'Solid' + tClass
      }
      return tClass
    },
    getServiceImpactColour: function (serviceImpact) {
      var isSelectedPriority = this.$route.query.ticketsFilter === serviceImpact ? 'selected' : ''
      if (this.$route.query.ticketsFilter === undefined && serviceImpact.length === 0) {
        isSelectedPriority = 'selected'
      }
      if (this.$route.query.ticketsFilter === 'emergency' && serviceImpact === 'critical') {
        isSelectedPriority = 'selected'
      }
      switch (serviceImpact ? serviceImpact.toLowerCase() : '') {
        case 'risk':
          return 'Blue ' + isSelectedPriority
        case 'high':
          return 'Purple ' + isSelectedPriority
        case 'critical':
          return 'Red ' + isSelectedPriority
        case 'medium':
          return 'Amber ' + isSelectedPriority
        case 'low':
          return 'Green ' + isSelectedPriority
        default:
          return 'Grey ' + isSelectedPriority
      }
    },
    getDayFilterColour: function (dayNo) {
      var isSelectedPriority = ''
      if (dayNo && this.$route.query.daysFilter) {
        isSelectedPriority = this.$route.query.daysFilter.toString() === dayNo.toString() ? 'active' : ''
      }
      if (this.$route.query.daysFilter === undefined && dayNo.length === 0) {
        isSelectedPriority = 'active'
      }
      return isSelectedPriority
    },
    getFiteredTickets: function (ticketsFilter) {
      var rtn = []
      if (ticketsFilter === 'emergency') {
        this.pmnTicketList.forEach(ticket => {
          if (this.getUDFValue(ticket.id, 'Planned Maintenance Category').toLowerCase() === 'emergency') {
            rtn.push(ticket)
          }
        })
      } else {
        this.pmnTicketList.forEach(ticket => {
          if (this.getUDFValue(ticket.id, 'Service Impact').toLowerCase().indexOf(ticketsFilter) > -1) {
            rtn.push(ticket)
          }
        })
      }
      return rtn
    },
    applyFilter: function (ticketsFilter) {
      this.currentDayFilter = 0
      this.$router.push({ path: 'pmn', query: { ticketsFilter } })
      // clear the currently shown tickets
      this.visibleTickets = []
      this.selectedTicket = null
      // recalculate the tickets to display
      this.computeTickets()
    },
    getDayTickets: function (daysNo) {
      var rtn = []
      this.pmnTicketList.forEach(ticket => {
        if (!daysNo || daysNo === 0) {
          rtn.push(ticket)
        } else {
          if (new Date(ticket.dueDateTime) > new Date() &&
            new Date(ticket.dueDateTime) < new Date(new Date().setDate(new Date().getDate() + Number(daysNo)))) {
            rtn.push(ticket)
          }
        }
      })
      return rtn
    },
    applyDayFilter: function (daysFilter) {
      this.currentDayFilter = daysFilter
      this.$router.push({ path: 'pmn', query: { daysFilter } })
      // clear the currently shown tickets
      this.visibleTickets = []
      this.selectedTicket = null
      // recalculate the tickets to display
      this.computeTickets()
    },
    getTicketCount: function (serviceImpact) {
      if (!this.pmnTicketList) {
        return 0
      } else {
        if (this.pmnTicketList.length === 0) {
          return 0
        } else {
          if (this.pmnTicketList[0].length === 0) {
            return 0
          } else {
            if (serviceImpact.length > 0) {
              if (serviceImpact === 'Emergency Category') {
                return this.pmnTicketList
                  .filter(t => this.getUDFValue(t.id, 'Planned Maintenance Category') === 'Emergency').length
              } else {
                return this.pmnTicketList
                  .filter(t => this.getUDFValue(t.id, 'Service Impact') === serviceImpact &&
                    this.getUDFValue(t.id, 'Planned Maintenance Type') !== 'WSUS').length
              }
            } else {
              return this.pmnTicketList.filter(t => this.getUDFValue(t.id, 'Planned Maintenance Type') !== 'WSUS').length
            }
          }
        }
      }
    },
    setDialog: function (value) {
      this.dialog = value
    },
    getScreenDate (dte) {
      var rtn = ''
      if (dte) {
        var date = new Date(dte)
        var day = this.checkForZero(date.getDate())
        var year = this.checkForZero(date.getFullYear())
        var month = this.checkForZero(date.getMonth() + 1)
        var hours = this.checkForZero(date.getHours())
        var minutes = this.checkForZero(date.getMinutes())
        rtn = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
      }
      return rtn
    },
    checkForZero (int) {
      if (int.toString().length === 1) {
        int = '0' + int
      }
      return int
    },
    openLinkedTicket: async function () {
      var element = await this.getTicketById(this.ticketLink)
      var tempTicket = {
        id: element.id,
        Title: element.title,
        Ticket_Number: element.ticketNumber,
        Date_Added: this.getScreenDate(element.createDateTime),
        Date_Due: this.getScreenDate(element.dueDateTime),
        Estimated_Hours: element.estimatedHours,
        Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
        Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
        Problem_Ticket_Id: element.problemTicketId,
        Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
        Service_Impact: this.getUDFValue(element.id, 'Service Impact'),
        PM_Reference: this.getUDFValue(element.id, 'Planned Maintenance Reference'),
        PM_Type: this.getUDFValue(element.id, 'Planned Maintenance Type')
      }
      if (element.accountId != null && element.accountId !== 0) {
        const siteObj = this.$store.getters.site(element.accountId)
        if (siteObj) {
          tempTicket.Site = siteObj.name
        }
      }
      tempTicket.Contact_Name = 'Unknown'
      this.selectedTicket = tempTicket
      this.selected = this.ticketLink
    },
    openTicket: async function (ticketId) {
      this.showDetails = false
      var element = await this.getTicketById(ticketId)
      var tempTicket = {
        id: element.id,
        Title: element.title,
        Ticket_Number: element.ticketNumber,
        Date_Added: this.getScreenDate(element.createDateTime),
        Date_Due: this.getScreenDate(element.dueDateTime),
        Estimated_Hours: element.estimatedHours,
        Issue_Type: this.getIssueTypeFromId(element.issueTypeId),
        Sub_Issue_Type: this.getSubIssueTypeFromId(element.subIssueTypeId),
        Problem_Ticket_Id: element.problemTicketId,
        Customer_Reference: this.getUDFValue(element.id, 'Customer Reference'),
        Service_Impact: this.getUDFValue(element.id, 'Service Impact'),
        PM_Reference: this.getUDFValue(element.id, 'Planned Maintenance Reference'),
        PM_Type: this.getUDFValue(element.id, 'Planned Maintenance Type')
      }
      if (element.accountId != null && element.accountId !== 0) {
        const siteObj = this.$store.getters.site(element.accountId)
        if (siteObj) {
          tempTicket.Site = siteObj.name
        }
      }
      element.createdByName = 'Unknown'
      this.selectedTicket = tempTicket
      this.selectedTicketFull = element
      this.showDetails = true
      this.selected = ticketId
    },
    getUDFValue (id, key) {
      var udf = this.currentTicketUDFs.filter(u => u.id === id)
      for (let i = 0; i < udf.length; i++) {
        if (udf[i].name === key) {
          return udf[i].value
        }
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .openIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .openQueryButton {
    margin:0 5px;
  }

  .appSectionContainer {
    margin-bottom: 40px;
  }
  .dayFilterContainer {
    display: flex;
  }
  .dayFilter {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    font-size: 0.86em;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    color: #fff;
    background-color: #444ed3;
    /* text-shadow: 2px 2px 2px rgb(156 147 147 / 87%); */
  }
  .dayFilter.active {
    color: rgb(255, 255, 101);
  }
  .Brown {
    background-color:  rgb(193, 183, 164);
    border: 2px rgb(155, 139, 116) solid;
  }

  .Blue {
    background-color: rgb(137, 194, 255);
    border: 2px rgb(98, 147, 221) solid;
  }

  .Red {
    background-color: rgb(235, 102, 100);
    border: 2px rgb(241, 31, 27) solid;
  }

  .Amber {
    background-color: rgb(255, 172, 49);
    border: 2px rgb(240, 150, 16) solid;
  }

  .Green {
    background-color: rgb(102, 187, 106);
    border: 2px rgb(60, 165, 65) solid;
  }

  .Grey {
    background-color: rgb(189, 188, 188);
    border: 2px rgb(168, 167, 167) solid;
  }

  .Purple {
    background-color: rgb(182, 110, 241);
    border: 2px rgb(155, 48, 243) solid;
  }

  .impactFilterContainer {
    display: flex;
  }

  .priorityIcon {
    flex: 1;
    margin: 5px 5px 10px 5px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 400;
    font-size: 0.86em;
    cursor: pointer;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    /* text-shadow: 2px 2px 2px rgb(156 147 147 / 87%); */

  }

  .selected {
    color: #fff;
  }

  .appSectionHeader {
    min-height: 30px;
    margin-bottom: 10px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .completedStatus {
    display:inline-block;
    margin-right: 15px;
  }

  .completedStatusText {
    display:inline-block;
    font-weight: 500;
    vertical-align: top;
  }

  .divSpinner {
    text-align: center;
    margin-top: 60px;
  }

  .pSpinnerText {
    font-weight: bold;
  }
</style>
